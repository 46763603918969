import parseCurrency from "./parseCurrency";

const getFieldByKey = (meta, key) => {
    for (const f of meta) {
        if (f.key.trim().toLowerCase() === key.trim().toLowerCase())
            return f;
    }
    return null;
};

export default (meta, key, forceNumber=false) => {
    const results = {}; // Keep track of evaluated results.
    
    const evaluate = (key) => {
        let value = getFieldByKey(meta, key);
        if (!value) return 0;
    
        if (!value.expression)
            return parseCurrency(value.value, forceNumber);
    
        // Check for invalid start to prevent eval errors
        if (value.expression.trim().startsWith('==')) {
            return "🚫 ERROR";
        }
    
        value = value.expression;
    
        try {
            const matches = value.match(/{(.*?)}/g);
            if (matches) {
                for (const match of matches) {
                    const innerKey = match.substring(1, match.length - 1);
                    if (!results[innerKey]) results[innerKey] = evaluate(innerKey);
                    value = value.replaceAll(match, results[innerKey]);
                }
            }
    
            // Evaluate the expression safely
            // eslint-disable-next-line
            return eval(value.substring(1));
        } catch (error) {
            console.error("Evaluation error:", error);
            return "🚫 ERROR";
        }
    };
    

    // Evaluate this field key
    const tmp = evaluate(key);
    
    if (!forceNumber && typeof tmp === "number") {
        return tmp < 0 ? `(${Math.abs(tmp).toLocaleString('en-US', { style: 'currency', currency: 'USD', minFractionDigits: 2, maxFractionDigits: 2 }).substring(1)})` : tmp.toLocaleString('en-US', { style: 'currency', currency: 'USD', minFractionDigits: 2, maxFractionDigits: 2 }).substring(1);
    }
    return tmp;

}