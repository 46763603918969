import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../../styles/import.css';
import api from '../../../api';
import ImportFileProvider from './FileProvider';

const SCRIPT_TEMPLATE = "# The Google Drive integration will run the function below which needs to scan for\n# appropriate files in the given folder and return the list of relative paths\n# from the folder to upload.\n\n# If the file exists in Stax.ai and has not been modified, it will not be\n# uploaded even if you return it from the function.\n\ndef find_files_to_upload(folder):\n    files = []\n    for file in os.listdir(folder):\n        files.append(os.path.join(folder, file))\n    \n    return files \n";

class ImportGoogleDrive extends ImportFileProvider {
    // State from ImportFileProvider
    scriptTemplate = SCRIPT_TEMPLATE;
    code = null;
    
    loadConfig = async () => {
        // loading all the existing stacks for this team   
        const res = await api.get('/stack/all?minimal=true');
        if (res && res.success && res.stacks)
            this.setState({ stacks: res.stacks });
        else return toast.error("Failed to load stacks list");

        const { connectionIdx } = this.props.data;
        const config = this.props.team.sync?.googledrive?.[connectionIdx] || {
            oauth: '',
            configurations: [],
            authenticated: false,
        }

        this.setState({
            ...config,
            configurations: config.folders || [],
            authenticated: false,
            scriptTemplate: SCRIPT_TEMPLATE,
        });
    }

    saveConfig = async () => {
        const sync = this.props.team?.sync ?? {};
        const connections = sync?.googledrive ?? [];
        const connectionIdx = this.props.data?.connectionIdx ?? connections.length;

        const connection = {
            user: this.props.user._id,
            oauth: this.state.oauth,
            ...(connections[connectionIdx] || {}),
            folders: this.state.configurations,
            moveUploadedFiles: this.state.moveUploadedFiles,
        };

        if (connections.length === 0) 
            connections.push(connection);
        else connections[connectionIdx] = connection;

        // Create stacks for all selected folders
        for (let folder of connection.folders) {
            const existingStack = this.state.stacks.find((stack) => stack.path === folder.name);
            if (existingStack){
                continue;
            }

            const res = await api.post('/stack/create', { stackPath: folder.name });
            if (!res || !res.stack) {
                toast.error("Failed to create a stack for the folder: " + folder.source);
                return this.props.loaded();
            } else {
                const destination = res.stack._id;
                // Update the folder with the stack id
                folder.destination = destination;
                // Update the folder in the connection
                connection.folders = connection.folders.map((f) => {
                    if(f.name === folder.name) {
                        return folder;
                    }
                    return f;
                });
            }
            this.setState({ stacks: [...this.state.stacks, res.stack] });
        }

        sync.googledrive = connections;
        await this.props.updateTeam({ sync });

        const res = await api.get('/sync/connections?source=googledrive');
        if (res && res.connections && res.connections.length > 0) {
            await api.post('/sync/manual', {
                connectionId: res.connections[connectionIdx > 0 ? connectionIdx : connections.length - 1]._id,
                source: 'googledrive',
            });

            toast.info("Your Google Drive files will start syncing shortly");
            this.props.loaded();
            this.props.modal();
        }
    }

    authenticate = async () => {
        let oauthId = this.state.oauth;
        const params = new URLSearchParams(this.props.location.search);
        if (this.state.oauth) {
            const res = await api.get(`/sync/gdrive/refresh?oauth=${this.state.oauth}`);
            if (!res?.success ?? false) {
                return toast.error("Unable to refresh the Google drive connection");
            }
        } else {
            // Handle authentication... 
            this.code = params.get('code');
            try {
                const res = await api.post("/sync/gdrive/token", { code: this.code });
                if (res && res.oauthId) {
                    this.setState({ oauth: res.oauthId, authenticated: true });
                    toast.success("Google Drive authenticated successfully");
                } else {
                    toast.error("Failed to authenticate with Google Drive");
                }
                oauthId = res.oauthId;
            } catch (error) {
                toast.error("Error during authentication");
            }
    }   
        
        this.setState({ oauth: oauthId, authenticated: true });
    }

    loadRoot = async () => {
        this.setState({ loading: true });
        const { folders } = await this.listFolders();
        this.setState({
            folders,
            loading: false
        });
    }

    listFolders = async (folderId = "root") => {
        

        const folders = [];
        const entries = [];

        const res = await api.post("/sync/gdrive/list", {
            oauth: this.state.oauth,
            folderId: folderId === "root" ? "root" : folderId
        });

   

        if (!res || !res.success) {
            toast.error("Unable to list folders from Google Drive");
            return { folders: [] };
        }

        if (res.success && res.connection && res.connection.moveUploadedFiles !== undefined) {
            this.setState({ moveUploadedFiles: res.connection.moveUploadedFiles });
        }

        entries.push(...res.entries);

        for (const entry of entries) {
            if (entry.mimeType !== "application/vnd.google-apps.folder")
                continue;

            folders.push({
                name: entry.name,
                path: entry.path,
                path_display: entry.path,
                shared: false,
                _id: entry.id,
                numDocuments: 0,
                loadChildren: async () => {
                    const children = await this.listFolders(entry.id);
                    this.loading = false;
                    this.loaded = true;
 
                    this.setState({ folders: [...this.state.folders, ...children.folders] });
                }
            });
        }


        return { folders };
    }

    renderDescription = () => {
        return (
            <div>
                <h1>Sync documents from Google Drive</h1>
                <p>
                    Stax·ai will make stacks for each Google Drive folder you select below and mirror the contents of these folders into your new stacks.
                </p>
            </div>
        )
    }

    renderContent = () => {
        return <div>{this.renderConfigEditor()}</div>
    }
}

export default withRouter(ImportGoogleDrive);
